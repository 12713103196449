const githubConfig = {
  username: "filess",
  repoList: Array.from(
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    (e) => `img${e}`
  ),
  branch: "main",
  accessTokenList: [
    "7715d7ca67b5d3837cfdoocsmde8c38421815aa423510af",
    "c411415bf95dbe39625doocsmd5047ba9b7a2a6c9642abe",
    "2821cd8819fa345c053doocsmdca86ac653f8bc20db1f1b",
    "445f0dae46ef1f2a4d6doocsmdc797301e94797b4750a4c",
    "cc1d0c1426d0fd0902bdoocsmdd2d7184b14da61b86ec46",
    "b67e9d15cb6f910492fdoocsmdac6b44d379c953bb19eff",
    "618c4dc2244ccbbc088doocsmd125d17fd31b7d06a50cf3",
    "a4b581732e1c1507458doocsmdc5b223b27dae5e2e16a55",
    "77904db41aee57ad79bdoocsmd760f848201dac9c96fd5e",
    "02f251cb14ac62ab100doocsmdddbfc8527d773f1f04ce1",
    "eb321079a95ba7028d9doocsmde2e84c502dac70de7cf08",
    "22f74fcfb071a961fa2doocsmde28dabc746f0503a15e5d",
    "85124c2bfe7abba0938doocsmd0af7f67918b99d085a5fd",
    "0a561b4d4bbecb2de7edoocsmdd9ba3833d11dbc5e430f5",
    "e8a01491188d8d5a097doocsmd03ede0aad1fe9e3af24e9",
    "36e1f420d7e5bdebd67doocsmd65463562f5f25b20b8377",
  ],
};

const giteeConfig = {
  username: "filesss",
  repoList: Array.from(
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    (e) => `img${e}`
  ),
  branch: "main",
  accessTokenList: [
    "ed5fc9866bd6c2fdoocsmddd433f806fd2f399c",
    "5448ffebbbf1151doocsmdc4e337cf814fc8a62",
    "25b05efd2557ca2doocsmd75b5c0835e3395911",
    "11628c7a5aef015doocsmd2eeff9fb9566f0458",
    "cb2f5145ed938dedoocsmdbd063b4ed244eecf8",
    "d8c0b57500672c1doocsmd55f48b866b5ebcd98",
    "78c56eadb88e453doocsmd43ddd95753351771a",
    "03e1a688003948fdoocsmda16fcf41e6f03f1f0",
    "c49121cf4d191fbdoocsmdd6a7877ed537e474a",
    "adfeb2fadcdc4aadoocsmdfe1ee869ac9c968ff",
    "116c94549ca4a0ddoocsmd192653af5c0694616",
    "ecf30ed7f2eb184doocsmd51ea4ec8300371d9e",
    "5837cf2bd5afd93doocsmd73904bed31934949e",
    "b5b7e1c7d57e01fdoocsmd5266f552574297d78",
    "684d55564ffbd0bdoocsmd7d747e5cc23aed6d6",
    "3fc04a9d272ab71doocsmd010c56cb57d88d2ba",
  ],
};

export { githubConfig, giteeConfig };
